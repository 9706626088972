import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "./internal.scss"
import { AiOutlineClockCircle } from "react-icons/ai"
import classNames from "classnames"
import { Col } from "react-bootstrap"

import DateTimeFormatService from "../../services/date-time-format.service"

export default props => {
  // console.log(props)

  const node = props.article

  return (
    <Col
      className={classNames(`article article__internal`, {
        sticky: node.sticky,
      })}
      xs={12}
      md={6}
      xl={4}
    >
      <Link to={node.path.alias}>
        <div className="inner">
          <div className={"title"}>
            <h3>{node.title}</h3>
          </div>
          {node.relationships.field_image &&
            node.relationships.field_image.localFile.childImageSharp && (
              <GatsbyImage
                image={node.relationships.field_image.localFile.childImageSharp.gatsbyImageData}
                loading="eager" />
            )}
          <div className={"description"}>
            <p>{node.field_description}</p>
          </div>
          <div className={"meta"}>
            <span>
              <AiOutlineClockCircle />
              <DateTimeFormatService date={node.created} />
            </span>
            <span>{node.relationships.field_article_type.name}</span>
          </div>
        </div>
      </Link>
    </Col>
  );
}
