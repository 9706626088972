import React from "react"
import "./date-time-format.service.scss"

/*
 * Props:
 * - Date
 */

export default props => {
  let time
  let hour
  let minute
  if (props.time) {
    time = props.time.split("+")[0].split(":")
    hour = time[0]
    minute = time[1]
  }

  let date
  let day
  let month
  let year
  if (props.date) {
    date = props.date.split("-")
    day = getDay(date[2])
    month = getMonth(date[1])
    year = date[0]
  }

  return (
    <div className={`date_time_format`} {...props}>
      {date && (
        <time>
          {day} {month} {year}
        </time>
      )}
      {time && (
        <time>
          {hour}:{minute}
        </time>
      )}
    </div>
  )
}

const getDay = day => {
  let rtn = ""
  let first = day[0]
  let last = day[1]

  if (first !== "0") {
    rtn += first
  }
  rtn += last

  switch (last) {
    case 1:
      rtn += "st"
      break
    case 2:
      rtn += "nd"
      break
    case 3:
      rtn += "rd"
      break
    default:
      rtn += "th"
  }

  return rtn
}

const getMonth = month => {
  const months = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return months[parseInt(month)]
}
