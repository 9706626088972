import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames"

import { Container, Row, Col } from "react-bootstrap"

export default ({ title, description, image, imageMobile }) => (
  <Container
    className={"d-flex c_PageHeader dark"}
    fluid={true}
    style={{
      minHeight: "256px",
      position: "relative",
      overflow: "hidden",
    }}
  >
    {/* Image */}
    {image && (
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        className={classNames(`d-none d-md-block`)}
        style={{
          position: "absolute",
          minWidth: "100%",
          minHeight: "100%",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }} />
    )}
    {/* Image */}
    {imageMobile && (
      <GatsbyImage
        image={imageMobile.childImageSharp.gatsbyImageData}
        className={classNames(`d-md-none`)}
        style={{
          position: "absolute",
          minWidth: "100%",
          minHeight: "100%",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }} />
    )}
    {/* Content */}
    <Container className={"m-auto"}>
      <Row className={"justify-content-start"}>
        <Col xs={12} md={8} xl={6}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Col>
      </Row>
    </Container>
  </Container>
)
