import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Col } from "react-bootstrap"
import classNames from "classnames"

import "./external.scss"

function hasClass(element, cls) {
  return (" " + element.className + " ").indexOf(" " + cls + " ") > -1
}
const onFocusArticle = event => {
  let currentTarget = event.currentTarget
  setTimeout(() => {
    currentTarget.classList.add("isFocused")
  }, 50)
}
const onUnfocusArticle = event => {
  let currentTarget = event.currentTarget
  currentTarget.classList.remove("isFocused")
}
const onClickArticle = event => {
  let currentTarget = event.currentTarget
  if (!hasClass(currentTarget, "isFocused")) event.preventDefault()
}

export default props => {
  // console.log(props)

  const node = props.article

  return (
    <Col
      className={classNames(`article article__external`, {
        sticky: node.sticky,
      })}
      xs={12}
      md={6}
      xl={4}
    >
      <a
        href={node.field_link.uri}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickArticle}
        onMouseEnter={onFocusArticle}
        onMouseLeave={onUnfocusArticle}
      >
        <div className="inner">
          {node.relationships.field_image &&
            node.relationships.field_image.localFile.childImageSharp && (
              <GatsbyImage
                image={node.relationships.field_image.localFile.childImageSharp.gatsbyImageData}
                loading="eager" />
            )}
          <Title node={node} />
          <Meta node={node} />
        </div>
      </a>
    </Col>
  );
}

const Title = ({ node }) => {
  return (
    <div className={"dark title"}>
      <div>
        <h3>{node.title}</h3>
        <p>
          {node.field_description.substring(0, 120)}
          {node.field_description.length > 120 && "..."}
        </p>
      </div>
    </div>
  )
}

const Meta = ({ node }) => {
  let meta = null

  if (
    node.relationships.field_icon &&
    node.relationships.field_icon.relationships.field_media_image.localFile &&
    node.relationships.field_icon.relationships.field_media_image.localFile
      .childImageSharp
  ) {
    meta = (
      <GatsbyImage
        image={node.relationships.field_icon.relationships.field_media_image
          .localFile.childImageSharp.gatsbyImageData} />
    )
  } else {
    meta = <span>{node.field_link.title}</span>
  }
  return <div className={"meta"}>{meta}</div>
}
