import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Masonry from "react-masonry-component"

import "./index.scss"
import Layout from "../../layouts/default"
import SEO from "../../components/seo"
import Section from "../../components/structural/section"
import CallToAction from "../../components/call-to-action"
import Header from "./header"
import FilterByType from "./byType"
import InternalArticle from "./internal"
import ExternalArticle from "./external"
import Disclaimer from "./disclaimer"

export default props => {
  // console.log(props)

  const [allArticles, setAllArticles] = useState([])

  const allInternal = props.data.allNodeArticle.edges
  const allExternal = props.data.allNodeArticleExternal.edges
  const allTypes = props.data.allTaxonomyTermArticleType.edges

  // TODO: This conditional shouldn't directly specify length, what if we have more types

  if (allTypes.length === 3) {
    allTypes.push({
      node: {
        id: "articles-links",
        name: "Links",
        path: { alias: "/links" },
      },
    })
  }

  /* migrating-from-v2-to-v3: The deprecated API pathContext was removed. You need to rename instances of it to pageContext
  
const filteredByType = props.pathContext.id
   ? allTypes.filter(edge => edge.node.id === props.pathContext.id)[0].node
  : null */

  const filteredByType = props.pageContext.id
    ? allTypes.filter(edge => edge.node.id === props.pageContext.id)[0].node
    : null

  useEffect(() => {
    let tmp = []
    //////////////////////////////////////////////////
    // Collect articles, by type
    // - type: all
    if (filteredByType === null) {
      tmp = [...allInternal, ...allExternal]
    }
    // - type: links
    else if (filteredByType.id === "articles-links") {
      tmp = [...allExternal]
    }
    // - type: other
    else {
      tmp = [
        ...allInternal.filter(article => {
          let articleType = article.node.relationships.field_article_type
          return articleType.id === filteredByType.id
        }),
      ]
    }
    tmp.sort((a, b) => b.node.created.localeCompare(a.node.created))
    tmp.sort((a, b) => Number(b.node.sticky) - Number(a.node.sticky))
    setAllArticles([...tmp])
  }, [filteredByType, allInternal, allExternal])

  //////////////////////////////////////////////////
  // Page props
  let pageTitle = (filteredByType && filteredByType.name) || "All Articles"
  let pageDescription =
    (filteredByType &&
      filteredByType.description &&
      filteredByType.description.processed) ||
    "<p>Get the latest opinion articles, press releases, links, and news; from home and around the the world.</p>"
  let pageImage =
    (filteredByType &&
      filteredByType.relationships &&
      filteredByType.relationships.field_image &&
      filteredByType.relationships.field_image.relationships.field_media_image
        .localFile) ||
    props.data.placeholderImage

  let pageImageMobile =
    (filteredByType &&
      filteredByType.relationships &&
      filteredByType.relationships.field_image_mobile &&
      filteredByType.relationships.field_image_mobile.relationships
        .field_media_image.localFile) ||
    props.data.placeholderImageMobile

  // Setup SEO Meta Fields
  // TODO: Move index pages to pages in Drupal, to grant better control
  let seoTitle = undefined
  let seoDescription = undefined
  switch (filteredByType && filteredByType.name) {
    case "Press Releases":
      seoTitle = "Press Releases for Safety Evolved – EHS Software "
      seoDescription =
        "Read our latest press releases for mentioning Safety Evolved. Safety Evolved is a leading EHS software provider in Australia."
      break
    case "News":
      seoTitle = "News | EHS Latest News – Safety Evolved"
      seoDescription =
        "Discover the latest Environmental, Health & Safety (EHS) and WHS news within Australia and worldwide. Stay up-to-date with here."
      break
    case "Opinions":
      seoTitle = "Opinion Pieces | EHS Industry Articles – Safety Evolved"
      seoDescription =
        "Discover Safety Evolved's latest opinion pieces on Environmental, Health & Safety and Workplace Health & Safety topics in Australia. Stay up-to-date here."
      break
    case "Links":
      seoTitle = "Links | EHS Links & Publications – Safety Evolved"
      seoDescription =
        "Discover the latest news and government links on EHS and WHS software within Australia and worldwide. Stay up-to-date here."
      break
    default:
      seoTitle = "Articles | EHS Latest Articles & Info – Safety Evolved"
      seoDescription =
        "Discover the latest articles on EHS & WHS within Australia and worldwide. Read the latest links, news, publications, news and more."
  }

  //////////////////////////////////////////////////
  // Page render

  let id = "articles__index"
  let background = { format: undefined }

  return (
    <Layout key={props.pageContext.id} id={id}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={pageImage && pageImage.publicURL}
      />
      <Header
        title={pageTitle}
        description={pageDescription}
        image={pageImage}
        imageMobile={pageImageMobile}
      />
      <FilterByType types={props.data.allTaxonomyTermArticleType.edges} />

      <Section id={`${id}__section`} className={`feed`} background={background}>
        <Container>
          <Row>
            <Col>
              <Masonry
                className={"row masonry"} // default ''
                elementType={"div"} // default 'div'
                options={{
                  transitionDuration: 0,
                }} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                // imagesLoadedOptions={imagesLoadedOptions} // default {}
              >
                {allArticles.map((article, _) => {
                  const node = article.node
                  const isExternal = node.relationships.field_article_type
                    ? false
                    : true
                  switch (isExternal) {
                    case true: // link type id
                      return <ExternalArticle key={node.id} article={node} />
                    default:
                      return <InternalArticle key={node.id} article={node} />
                  }
                })}
              </Masonry>
            </Col>
          </Row>
        </Container>
      </Section>

      <Disclaimer />
      <CallToAction />
    </Layout>
  )
}

export const query = graphql`
  {
    placeholderImage: file(relativePath: { eq: "articles-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      publicURL
    }
    placeholderImageMobile: file(
      relativePath: { eq: "articles-banner-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
      publicURL
    }
    allTaxonomyTermArticleType {
      edges {
        node {
          id
          name
          path {
            alias
          }
          description {
            processed
          }
          relationships {
            field_image {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeArticle(sort: { order: DESC, fields: [created] }) {
      edges {
        node {
          id
          title
          created
          sticky
          field_description
          field_hide_image
          path {
            alias
          }
          field_image {
            alt
          }
          relationships {
            field_article_type {
              path {
                alias
              }
              name
            }
            field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
                publicURL
              }
            }
            field_article_type {
              id
              name
              path {
                alias
              }
            }
          }
        }
      }
    }
    allNodeArticleExternal(sort: { order: DESC, fields: [created] }) {
      edges {
        node {
          id
          title
          created
          sticky
          field_description
          field_link {
            title
            uri
          }
          field_image {
            alt
          }
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
                publicURL
              }
            }
            field_icon {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 20, layout: FIXED)
                    }
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
