import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Section from "../../components/structural/section"
import "./byType.scss"

export default ({ types }) => {
  let id = "ID_article_by_type"
  let background = { format: undefined }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <Col xs={12} style={{ paddingBottom: 0 }}>
            <p style={{ color: "#034ea2" }}>Filter by:</p>
          </Col>
          <Col xs={12} className={"dark"} style={{ paddingBottom: 0 }}>
            <div
              style={{
                paddingBottom: 0,
                backgroundColor: "#034ea2",
                float: "left",
                width: "100%",
              }}
            >
              {types.map((item, index) => {
                let node = item.node
                return (
                  <div key={node.path.alias} className="filter-type">
                    {index !== 0 && (
                      <span className={"d-none d-md-inline-block"}>|</span>
                    )}
                    <span className={"d-inline-block"}>
                      <Link to={`/articles${node.path.alias}`}>
                        {node.name}
                      </Link>
                    </span>
                  </div>
                )
              })}
              <div className="filter-type">
                <span>
                  <Link to={`/articles`}>All Articles</Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
