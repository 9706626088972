import React from "react"

import { Container } from "react-bootstrap"

export default () => (
  <section
    style={{
      backgroundColor: "white",
      padding: 10,
      boxShadow: "rgba(0, 0, 0, 0.25) 5px -5px 15px",
    }}
  >
    <Container style={{ padding: "0 15px" }}>
      <p
        style={{
          fontSize: 13,
          textAlign: "center",
          margin: 0,
        }}
      >
        *Safety Evolved cannot control the content on external pages and take no
        responsibility for information displayed that derive from links on this
        website.
      </p>
    </Container>
  </section>
)
